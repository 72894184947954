import React from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Card from '../components/Card';
import Quote from '../components/Quote';
import FeatureSlider from '../components/FeatureSlider';

import graphicClinicalPros from '../images/graphic_clinicalpros.svg';
import clinicalInstru from '../images/clinical_instruments.png';
import clinicalHealth from '../images/health_card.png';
import clinicalEmergency from '../images/emergency.png';
import clinicalHistory from '../images/history.png';

import networkmap from '../images/icon-networkmap.svg';
import microscope from '../images/icon-microscope.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';

const featureSlides = [
  {
    image: clinicalInstru,
    title:'Clinical Instruments',
    content:'Clinically validated assessments and instruments are housed within the platform so patients can complete them offsite at any time to provide best care and preventative interventions.',
  },
  {
    image: clinicalHealth,
    title:'Health Cards',
    content:'Innowell’s health cards allow real-time, in-depth insights into a patient\'s wellbeing across all domains of mental health, allowing professionals to best prepare for care.',
  },
  {
    image: clinicalEmergency,
    title:'Emergency Contact',
    content:'In-built emergency help features ensure patients have aid at all levels of severity 24/7, with early intervention engaged for patients who require emergency help or are in danger of self-harm.',
  },
  {
    image: clinicalHistory,
    title:'Patient Histories',
    content:'Detailed patient history shared between full circle-of-care enables best mental health support, while the patient is provided full transparent access to their own clinical information and privacy.',
  },
]

const YourClinicalProfessionals = () => {

  return (
    <Page title='Your Clinical Professionals'>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Graphic for Clinical Professionals' src={graphicClinicalPros}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>People-first clinical care </Typography>
            <Typography type='paragraph' preset={2}>
              Built for patients, practitioners, and administrators, Innowell empowers your personalised clinical care with accelerated assessment and triage, real-time risk monitoring, and individual patient care plans.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
        Every year 1-in-3 Australians will need clinical mental health support, and the average wait time to see a qualified mental health professional is over 14 months. The sad news? Only half that number will get it, and when they do it’s unlikely the first specialist they meet will be the right one for their care needs.
        </Typography>
        <Typography type='paragraph' preset={2}>
        Mental health care is an increasingly critical issue, not just for those who suffer but for the increasingly overloaded administrators and clinicians trying to help. The current system and tools in-place to properly analyse, triage, and intake those suffering from poor mental health are often not sufficient. 
        </Typography>
        <Typography type='heading' preset={2}>Your Solution</Typography>
        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Facilitate early intervention and reduce risk of hospital readmissions with accelerated risk assessment, triage, and intake.</Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Optimise care with stepped and staged models endorsed by the Brain & Mind Centre at the University of Sydney.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Increase engagement with care plans between consultations with shared plans of care and clinically validated assessments available remotely.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>
            Manage workflows with patient lists containing result, risks, activity and clinically validated instrument overviews.
            </Typography>
          </li>
        </ul>
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='I get to worry less about how to manage my patients and focus more on providing them the support they need.'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Data Driven icon'/>
              <Typography type='heading' preset={3}>Data Driven</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='People Centric icon'/>
              <Typography type='heading' preset={3}>Person Centric</Typography>
              <Typography type='paragraph' preset={2}>Engage patients with remote 24-7 access between consultations to clinically validated instruments. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={networkmap} alt='Interoperable icon'/>
              <Typography type='heading' preset={3}>Interoperable</Typography>
              <Typography type='paragraph' preset={2}>Integrated with full circle-of-care enhancing shared care plans with other practitioners. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Research Lead icon'/>
              <Typography type='heading' preset={3}>Research Lead</Typography>
              <Typography type='paragraph' preset={2}>Close collaboration with BMC provides innovative features and ongoing research-based enhancements.</Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* The Clinical Platform */}
      <Section title='The Clinical Platform'>
        <FeatureSlider slides={featureSlides}/>
      </Section>

    </Page>
  )
};

export default YourClinicalProfessionals;
